<style lang="scss">
.custom-tag {
    padding: 20px 0 20px 20px;

    .el-tag {
        margin: 0 20px 20px 0;
        width: 176px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        position: relative;

        &:hover {
            opacity: .7;
        }

        i {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>

<template>
    <div class="scroll-div">
        <template v-if="message">
            <ts-error></ts-error>
        </template>
        <template v-else>
            <div class="total-title">药品大类历年销售趋势
                <div class="total-title__right">大类筛选：
                    <el-select v-model="treatBig" filterable reserve-keyword placeholder="请输入关键词" @change="initData">
                        <el-option v-for="(item, i) in selectList" :key="'treatBigA' + i"
                            :label="item.treatCategoryName" :value="item.treatCategoryName"></el-option>
                    </el-select>
                </div>
            </div>
            <div :id="nameKey" class="canvas small"></div>

            <div class="total-title__line"></div>
            <div class="total-title">药品大类历年销售占比趋势图</div>
            <div :id="nameKey + 1" class="canvas small"></div>

            <div class="total-title__line"></div>
            <div class="total-title">
                药品分类历年销售趋势
                <div class="total-title__right">（销售额单位：{{ $variable.unit }}）</div>
            </div>
            <div class="treat-panel">
                <div class="treat-panel__item">大类：
                    <el-select v-model="treatBigMy.model" filterable :loading="treatBigMy.loading" value-key="id"
                        placeholder="请选择大类" @change="treatBigChange">
                        <el-option v-for="item in treatBigMy.list" :key="item.id" :value="item"
                            :label="item.treatCategoryName"></el-option>
                    </el-select>
                </div>
                <div class="treat-panel__item" v-show="treatBigMy.model.id">亚类：
                    <el-select v-model="treatSmall.model" filterable :loading="treatSmall.loading" value-key="id"
                        multiple reserve-keyword collapse-tags placeholder="请选择亚类" @change="treatSmallChange">
                        <el-option v-for="item in treatSmall.list" :key="item.id" :value="item"
                            :label="item.treatCategoryName"></el-option>
                    </el-select>
                </div>
                <div class="treat-panel__item" v-show="treatSmall.model.length === 1">三类：
                    <el-select v-model="treatThree.model" filterable :loading="treatThree.loading" value-key="id"
                        multiple reserve-keyword collapse-tags placeholder="请选择三类" @change="treatThreeChange">
                        <el-option v-for="item in treatThree.list" :key="item.id" :value="item"
                            :label="item.treatCategoryName"></el-option>
                    </el-select>
                </div>
                <div class="treat-panel__item" v-show="treatThree.model.length === 1">四类：
                    <el-select v-model="treatFour.model" filterable :loading="treatFour.loading" value-key="id" multiple
                        reserve-keyword collapse-tags placeholder="请选择四类" @change="treatFourChange">
                        <el-option v-for="item in treatFour.list" :key="item.id" :value="item"
                            :label="item.treatCategoryName"></el-option>
                    </el-select>
                </div>
            </div>
            <div :id="nameKey + 2" class="canvas small"></div>
            <ts-table :cols="treatOther.cols" :data="treatOther.list" :height="null" hide-page hide-detail>
                <template #left>
                    <!-- <el-button class="txt-btn" type="text" icon="el-icon-document" @click="exportInfo">导出</el-button> -->
                </template>
            </ts-table>
        </template>
    </div>
</template>

<script>
export default {
    props: ['visitId'],
    data() {
        return {
            conditionList: [],

            loaded: false,
            loading: false,
            nameKey: 'corpSale',
            message: '',
            treatBig: '',
            treatBigCode: '',

            selectList: [],

            treatOther: {
                req: {
                    '0': {
                        api: 'saleTreatBig/saleYearTreatBig/sumAmountGroupByTreatSmallCode',
                        field: 'treatSmallCode',
                        showField: 'treatSmall',
                    },
                    '1': {
                        api: 'saleTreatBig/saleYearTreatBig/sumAmountGroupByTreatThreeCode',
                        field: 'treatThreeCode',
                        showField: 'treatThree',
                    },
                    '2': {
                        api: 'saleTreatBig/saleYearTreatBig/sumAmountGroupByTreatFourCode',
                        field: 'treatFourCode',
                        showField: 'treatFour',
                    },
                },

                cols: this.$help.generalCols([
                    {
                        fieldName: 'name',
                        name: '药品类型',
                        width: 150,
                    },
                    ...this.$variable.years.map(item => {
                        return this.$help.joinObj({
                            fieldName: 'amount' + item.value,
                            name: item.value + '年',
                            listOrderView: true,
                            width: 100,
                            listClass: 'tc'
                        })
                    }),
                ]),
                list: [],
            },


            treatBigMy: {
                model: {},
                loading: false,
                list: [],
            },
            treatSmall: {
                model: [],
                loading: false,
                list: [],
            },
            treatThree: {
                model: [],
                loading: false,
                list: [],
            },
            treatFour: {
                model: [],
                loading: false,
                list: [],
            },
        }
    },
    mounted() {
        // 获取所有药品大类
        this.$api.post('ba/baTreatCategory/queryBaTreatCategorySelect', { params: { parentId: '0' } })
            .then(res => {
                if (res.code === this.$variable.successCode) {
                    if (res.data) {
                        this.selectList = res.data || []
                        if (this.selectList.length > 0) {
                            this.treatBig = this.selectList[0].treatCategoryName
                            this.treatBigCode = this.selectList[0].treatCategoryCode
                        }
                    }
                } else {
                    this.message = true
                }
            })
            .finally(() => {
                this.loaded = true
                this.initData()
            })



        // 默认加载第一个大类的前五个亚类
        this.loadCategory('0', this.treatBigMy, list => {
            if (list.length > 0) {
                this.treatBigMy.model = list[0]
                this.loadCategory(this.treatBigMy.model.id, this.treatSmall, smallList => {
                    if (smallList.length > 5) {
                        this.treatSmall.model = [smallList[0], smallList[1], smallList[2], smallList[3], smallList[4]]
                    } else {
                        this.treatSmall.model = smallList
                    }
                    this.renderOther()
                })
            }
        })
    },
    methods: {
        initData() {
            this.loading = true
            this.$api.get('saleTreatBig/saleYearTreatBig/findAimDrugsSaleYearTreatBigByTreatBig',
                { treatBigCode: this.treatBigCode }, { 'Visit-Id': this.$help.getVisitId(this.visitId) })
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        let list = []
                        let list1 = []
                        if (res.data) {
                            this.$variable.years.forEach(item => {
                                list.push({
                                    amount: res.data[`amount${item.value}`],
                                    year: item.value,
                                    rate: this.$help.parseFloat100(res.data[`rate${item.value}`]),
                                })
                                list1.push({
                                    year: item.value,
                                    value: this.$help.parseFloat100(res.data[`ratio${item.value}`]),
                                })
                            })
                        }
                        this.$help.renderBar(this.nameKey, list, 'year')
                        this.$help.renderLine(this.nameKey + 1, list1, 'year')
                    } else {
                        this.message = true
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        // 加载类型数据
        loadCategory(parentId, model, cb) {
            model.loading = true
            this.$api.post('ba/baTreatCategory/queryBaTreatCategorySelect', { params: { parentId } })
                .then(res => {
                    model.list = res.data
                    cb && cb(res.data)
                })
                .finally(() => {
                    model.loading = false
                })
        },

        treatBigChange() {
            this.treatSmall.model = []
            this.treatThree.model = []
            this.treatFour.model = []
            this.loadCategory(this.treatBigMy.model.id, this.treatSmall)
            this.renderOther()
        },
        treatSmallChange() {
            this.treatThree.model = []
            this.treatFour.model = []

            if (this.treatSmall.model.length === 1) {
                this.loadCategory(this.treatSmall.model[0].id, this.treatThree)
            }

            this.renderOther()
        },
        treatThreeChange() {
            this.treatFour.model = []

            if (this.treatThree.model.length === 1) {
                this.loadCategory(this.treatThree.model[0].id, this.treatFour)
            }

            this.renderOther()
        },
        treatFourChange() {
            this.renderOther()
        },
        renderOther() {
            let req = {}
            let val = []
            if (this.treatFour.model.length > 0) {
                req = this.treatOther.req['2']
                val = this.treatFour.model.map(item => item.treatCategoryCode)
            } else if (this.treatThree.model.length > 0) {
                req = this.treatOther.req['1']
                val = this.treatThree.model.map(item => item.treatCategoryCode)
            } else if (this.treatSmall.model.length > 0) {
                req = this.treatOther.req['0']
                val = this.treatSmall.model.map(item => item.treatCategoryCode)
            }
            this.treatOther.list = []
            if (req.api) {
                this.conditionList = [
                    {
                        name: req.field,
                        op: 'in',
                        value: val
                    }
                ]
                this.$api.post(req.api, {
                    conditionList: this.conditionList
                })
                    .then(res => {
                        if (res.data && res.data.length !== 0) {
                            res.data = res.data.map(item => {
                                item.name = item[req.showField]
                                return item
                            })
                        }
                        this.treatOther.list = res.data
                        this.$help.renderMulLine(this.nameKey + 2, res.data, req.showField)
                    })
            } else {
                window.echarts.init(document.getElementById(this.nameKey + 2)).clear()
            }

        },

        exportInfo(txt = '确定导出吗') {
            //导出条件
            const search = { conditionList: this.conditionList } 
            console.log('点击导出', search, txt);
            // this.$help.exportInfo(txt, "company/saleYear/exportSumQuarterAmountAndRateGroupByComNameAndSpec", search)
        },
    }
}
</script>
